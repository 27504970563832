import React from 'react';
import { AppHeaderComponent, AppButtonComponent } from "@unity/components";
import { Link } from 'react-router-dom';
import SuppliersForm from '../../modules/suppliers/SuppliersForm';
export default function PageTemplateStandardEdit(props) {
    return (
        <>
            <AppHeaderComponent name="suppliers" saveBtn={props.handleSave} context={props.context} subpage="edit" subpage2={props.data.client_name} history={props.history} theme={props.context.theme}
                addNew={() => <Link to={"/suppliers/create"}>
                    <AppButtonComponent
                        text="Add New"
                        btnIcon="add"
                        stylename="primary"
                        style={
                            {
                                background: props.context.theme.top_menu.button_background,
                                color: props.context.theme.top_menu.button_font,
                            }
                        }
                    />
                </Link>}

            />
            <div style={{ marginTop: 146 }}>
                <Link to={{ pathname: `/activities/communication/${props.route.match.params.id}/create`, state: { tag: "Supplier" } }}>
                    <AppButtonComponent
                        text="Add New Activity"
                        btnIcon="add"
                        style={{
                            background: props.context.theme.sidebar.background,
                            color: "white",
                            marginRight: 20
                        }}
                    />
                </Link>

                <Link to={{ pathname: `/activities/communication/client/${props.route.match.params.id}/all/index`, state: { filter: "Supplier" } }}>
                    <AppButtonComponent
                        text="View Activities"
                        btnIcon="visibility"
                        style={{
                            background: props.context.theme.sidebar.background,
                            color: "white",
                        }}
                    />
                </Link>
                <SuppliersForm
                    {...props}
                />
            </div>
        </>
    )
}