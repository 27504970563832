import React from "react";
import { AppHeaderComponent, AppButtonComponent } from "@unity/components";
import FilterTable from "../../common/FilterTable";
import ComponentTabs from "../../common/Tabs";
import SupplierSettings from "../../modules/suppliers/SupplierSettings";
import { Link } from "react-router-dom";
import Loading from "../../common/Loading";

export default function PageTemplateStandardIndex(props) {
  const tabs = [
    { icon: "check" },
    { icon: "help_outline" },
    { icon: "close" },
    // { icon: "settings" }, NOT REQUIRED FOR SUPPLIERS!
  ];

  const handleChange = (event, newValue) => {
    props.setTabValue(newValue);
  };

  return (
    <>
      <AppHeaderComponent
        name={"suppliers"}
        theme={props.context.theme}
        history={props.history}
        context={props.context}
        addNew={() => (
          <Link to={"/suppliers/create"}>
            <AppButtonComponent
              text="Add New"
              btnIcon="add"
              stylename="primary"
              style={{
                background: props.context.theme.top_menu.button_background,
                color: props.context.theme.top_menu.button_font,
              }}
            />
          </Link>
        )}
      />

      <ComponentTabs
        tabs={tabs}
        value={props.tabValue}
        setValue={props.setTabValue}
        handleChange={handleChange}
      />

      {!props.loading ? (
        <div style={{ marginTop: 146 }}>
          {props.tabValue < 3 && (
            <FilterTable
              columns={props.columns}
              data={props.data}
              options={props.options}
            />
          )}
          {props.tabValue === 3 && <SupplierSettings />}
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}
