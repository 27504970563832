import React, { useEffect, useState } from "react";
import Loading from "../common/Loading";
import { SuppliersApi } from "@unity/components";
import { RenderPageTemplate } from "../mapping";

export default function SuppliersView({ route, history, context }) {
  const [data, setData] = useState(false);
  const [products, setProducts] = useState(false);

  const getSupplierSingle = async () => {
    const res = await SuppliersApi.getSupplierSingle(route.match.params.id);
    setData(res.data);
  };

  const handleProductChange = (name, value, key) => {};

  const handleProductDelete = (key) => {};

  const handleProductAdd = () => {};

  useEffect(() => {
    getSupplierSingle();
  }, []);

  const handleChange = (name, value) => {};

  const RenderPage = RenderPageTemplate(context.auth.app_uuid, "view");

  if (data) {
    return (
      <>
        <RenderPage
          data={data}
          products={products}
          errors={false}
          setProducts={setProducts}
          handleChange={handleChange}
          handleProductChange={handleProductChange}
          handleProductDelete={handleProductDelete}
          handleProductAdd={handleProductAdd}
          setData={setData}
          type="view"
          context={context}
          history={history}
          route={route}
        />
      </>
    );
  } else {
    return <Loading />;
  }
}
