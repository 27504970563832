import React from "react";

export default function SupplierSettings() {
    return (
        <div className="block">
            <div className="row">
                <div className="col-lg-12">
                    <p style={{ margin: 0 }}>No supplier settings available.</p>
                </div>
            </div>
        </div>
    )
}