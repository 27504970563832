import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import ComponentTabs from "../common/Tabs";
import FileBase64 from "react-file-base64";
import CompaniesHouseModule from "../../../micro-suppliers/src/modules/companies-house/CompaniesHouseModule";
import ProductsForm from "./ProductsForm";
import { SuppliersApi } from "@unity/components";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";

export default function SuppliersStandardForm(props) {
  const tabs = [{ icon: "information" }, { icon: "home" }];
  const lower_tabs = [{ icon: "business" }, { icon: "build" }];

  const [country, setCountry] = useState("GB");
  const [tabValue, setTabValue] = useState(0);
  const [lowerTabValue, setLowerTabValue] = useState(0);
  const [companyTypes, setCompanyTypes] = useState([]);
  
  const code = getCountryCallingCode(country);

  const CountrySelect = ({ value, onChange, labels, ...rest }) => (
    <select
      {...rest}
      value={value}
      onChange={(event) => onChange(event.target.value || undefined)}
    >
      <option value="">{labels["ZZ"]}</option>
      {getCountries().map((country) => (
        <option key={country} value={country}>
          {labels[country]} +{getCountryCallingCode(country)}
        </option>
      ))}
    </select>
  );

  const handleFormat = (e) => {
    var val = e.target.value;
    const newData = { ...props.data };
    newData["telephone"] = val;
    newData["calling_code"] = "+" + getCountryCallingCode(country);
    props.setData(newData);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleLowerChange = (event, newValue) => {
    setLowerTabValue(newValue);
  };

  const getCompanyTypes = async () => {
    const res = await SuppliersApi.getSupplierTypes();
    setCompanyTypes(res.data);
  };

  useEffect(() => {
    getCompanyTypes();
  }, []);

  return (
    <>
      <ComponentTabs
        tabs={tabs}
        value={tabValue}
        setValue={setTabValue}
        handleChange={handleChange}
      />

      {tabValue === 0 && (
        <>
          <div className="block" style={{ marginBottom: 30 }}>
            <div className="container-fluid">
              <div className="row">
                <div
                  className="col-lg-3"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {props.data.logo_path && (
                    <div>
                      <div
                        style={{
                          backgroundImage: "url(" + props.data.logo_path + ")",
                          backgroundPosition: "center, center",
                          backgroundSize: "110%",
                          backgroundRepeat: "no-repeat",
                          height: 50,
                          width: 50,
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderColor: "#d2d2d2",
                          borderRadius: "100%",
                          display: "inline-block",
                        }}
                      />
                      <span
                        style={{
                          marginLeft: 10,
                          fontWeight: "bold",
                          fontSize: 20,
                        }}
                      >
                        {props.data.name}
                      </span>
                    </div>
                  )}
                  {props.type !== "view" && (
                    <FileBase64
                      multiple={false}
                      onDone={(e) => props.upload(e)}
                    />
                  )}
                </div>
                {props.type !== "create" && (
                  <div
                    className="col-lg-3"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Status:{props.data.approved && "Approved"}{" "}
                    {props.data.rejected && "Rejected"}{" "}
                    {!props.data.rejected && !props.data.approved && "Pending"}
                  </div>
                )}
                <div
                  className="col-lg-3"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <InputLabel style={{ fontSize: ".9rem", marginBottom: 1 }}>
                    Reference
                  </InputLabel>
                  <TextField
                    inputProps={{ style: { textAlign: "center" } }}
                    value={props.data.reference}
                    name="reference"
                    fullWidth
                    disabled
                  />
                </div>
                <div
                  className="col-lg-3"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <InputLabel style={{ fontSize: ".9rem", marginBottom: 1 }}>
                    Date Approved
                  </InputLabel>
                  <TextField
                    inputProps={{ style: { textAlign: "center" } }}
                    type="date"
                    value={props.data.approved_date}
                    name="approved_date"
                    disabled
                    fullWidth
                  />
                </div>
              </div>
            </div>
          </div>

          <ComponentTabs
            fixed={false}
            tabs={lower_tabs}
            value={lowerTabValue}
            setValue={setLowerTabValue}
            handleChange={handleLowerChange}
          />
          {lowerTabValue === 0 && (
            <div className="block">
              <div className="container-fluid">
                <div className="form-row">
                  <div
                    className="form-group col-lg-3"
                    style={{ paddingLeft: 90 }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 99,
                        left: 0,
                        top: 16,
                      }}
                    >
                      <img
                        src={`https://www.countryflags.io/${country}/shiny/32.png`}
                      />
                    </div>
                    <div className="country-select">
                      <CountrySelect
                        labels={en}
                        value={country}
                        style={{
                          position: "absolute",
                          top: 21,
                          height: 22,
                          zIndex: 99,
                          width: 18,
                          left: 30,
                        }}
                        onChange={setCountry}
                      />
                      <div
                        style={{
                          position: "absolute",
                          left: 54,
                          top: 18,
                          fontWeight: "bold",
                        }}
                      >
                        {"+" + code}
                      </div>
                      <TextField
                        label="Telephone"
                        value={props.data.telephone}
                        fullWidth={true}
                        name="telephone"
                        required
                        error={props.errors.telephone ? true : false}
                        helperText={props.errors.telephone}
                        onChange={(e) => handleFormat(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-4">
                    <TextField
                      label="Name"
                      value={props.data.name}
                      name="name"
                      onChange={(e) =>
                        props.handleChange(e.target.name, e.target.value)
                      }
                      fullWidth
                      required
                      error={props.errors.name ? true : false}
                      helperText={props.errors.name}
                    />
                  </div>
                  <div className="form-group col-lg-5">
                    <TextField
                      label="Email"
                      value={props.data.email}
                      name="email"
                      onChange={(e) =>
                        props.handleChange(e.target.name, e.target.value)
                      }
                      fullWidth
                      required
                      error={props.errors.email ? true : false}
                      helperText={props.errors.email}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-lg-6">
                    <TextField
                      label="House Number / Unit"
                      value={props.data.address_number}
                      name="address_number"
                      onChange={(e) =>
                        props.handleChange(e.target.name, e.target.value)
                      }
                      fullWidth
                      error={props.errors.address_number ? true : false}
                      helperText={props.errors.address_number}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <TextField
                      label="First Line Address"
                      value={props.data.address_first_line}
                      name="address_first_line"
                      onChange={(e) =>
                        props.handleChange(e.target.name, e.target.value)
                      }
                      fullWidth
                      error={props.errors.address_first_line ? true : false}
                      helperText={props.errors.address_first_line}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-lg-6">
                    <TextField
                      label="Town / County"
                      value={props.data.address_town}
                      name="address_town"
                      onChange={(e) =>
                        props.handleChange(e.target.name, e.target.value)
                      }
                      fullWidth
                      error={props.errors.address_town ? true : false}
                      helperText={props.errors.address_town}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                    <TextField
                      label="Postcode"
                      value={props.data.address_postcode}
                      name="address_postcode"
                      onChange={(e) =>
                        props.handleChange(e.target.name, e.target.value)
                      }
                      fullWidth
                      error={props.errors.address_postcode ? true : false}
                      helperText={props.errors.address_postcode}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                    <InputLabel style={{ fontSize: ".9rem", marginBottom: 1 }}>
                      Company Type
                    </InputLabel>
                    <Select
                      value={props.data.type}
                      fullWidth
                      name="type"
                      onChange={(e) =>
                        props.handleChange(e.target.name, e.target.value)
                      }
                    >
                      {companyTypes.map((item, key) => {
                        return (
                          <MenuItem key={key} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-lg-3">
                    <TextField
                      label="UTR"
                      value={props.data.utr}
                      name="utr"
                      onChange={(e) =>
                        props.handleChange(e.target.name, e.target.value)
                      }
                      fullWidth
                      error={props.errors.utr ? true : false}
                      helperText={props.errors.utr}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                    <TextField
                      label="DUNS"
                      value={props.data.duns}
                      name="duns"
                      onChange={(e) =>
                        props.handleChange(e.target.name, e.target.value)
                      }
                      fullWidth
                      error={props.errors.duns ? true : false}
                      helperText={props.errors.duns}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                    <TextField
                      label="Company Registration Number"
                      value={props.data.company_number}
                      name="company_number"
                      fullWidth
                      onChange={(e) =>
                        props.handleChange(e.target.name, e.target.value)
                      }
                      error={props.errors.company_number ? true : false}
                      helperText={props.errors.company_number}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                    <TextField
                      label="VAT Number"
                      value={props.data.vat_number}
                      name="vat_number"
                      fullWidth
                      onChange={(e) =>
                        props.handleChange(e.target.name, e.target.value)
                      }
                      error={props.errors.vat_number ? true : false}
                      helperText={props.errors.vat_number}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {lowerTabValue === 1 && (
            <ProductsForm
              errors={props.errors}
              supplier={props.data.id}
              products={props.products}
              categories={props.categories}
              setProducts={props.setProducts}
              setCategories={props.setCategories}
              handleProductChange={props.handleProductChange}
              handleProductAdd={props.handleProductAdd}
              handleProductDelete={props.handleProductDelete}
              handleProductSave={props.handleProductSave}
              type={props.type}
            />
          )}
        </>
      )}

      {tabValue === 1 && (
        <CompaniesHouseModule
          context={props.context}
          data={props.data}
          setData={props.setData}
        />
      )}
    </>
  );
}
