import React from 'react';
import PageTemplateStandardView from './page-templates/standard/view';
import PageTemplateStandardEdit from './page-templates/standard/edit';
import PageTemplateStandardCreate from './page-templates/standard/create';
import PageTemplateStandardIndex from './page-templates/standard';
import SuppliersStandardForm from './forms/SuppliersStandardForm';

const mapping = {
    //Add custom form layouts here
}

const RenderComponent = (uuid) => {

    if (mapping[uuid]) return mapping[uuid];
    return SuppliersStandardForm;

}

const page_mapping = {
    "default": {
        "view": PageTemplateStandardView,
        "edit": PageTemplateStandardEdit,
        "create": PageTemplateStandardCreate,
        "index": PageTemplateStandardIndex
    },
    "76635ee3-bb89-4ff4-86aa-b4dfae0887b4": PageTemplateStandardCreate,
    //Add custom form layouts here
}

const RenderPageTemplate = (uuid, type) => {

    if (page_mapping[uuid]) {
        if (page_mapping[uuid][type]) {
            return page_mapping[uuid][type]
        } else {
            return page_mapping['default'][type]
        }
    } else {
        return page_mapping['default'][type];
    }

}

export { RenderComponent, RenderPageTemplate };