import React, { useState } from 'react';
import SearchBar from "material-ui-search-bar";
import FilterTable from '../../common/FilterTable';
import { AppButtonComponent, ClientsApi } from "@unity/components";
import CompaniesHouseAttachPopup from './CompaniesHouseAttachPopup';

export default function CompaniesHouseFormSearch({ context, handleAttach }) {

    const [searchValue, setSearchValue] = useState("");
    const [data, setData] = useState(false);

    const [open, setOpen] = useState({ state: false, number: false });

    const handleSearch = async () => {
        const res = await ClientsApi.companiesHouseSearch(searchValue);
        setData(res.items)
    }

    const columns = [
        { name: "title", label: "Company Name / Title" },
        { name: "company_status", label: "Status" },
        { name: "company_number", label: "Company Number" },
        {
            name: "", label: "Premesis", options: {
                customBodyRenderLite: (dataIndex) => {
                    return <span>{data[dataIndex].address.premises}</span>
                }
            }
        },
        {
            name: "", label: "Address Line 1", options: {
                customBodyRenderLite: (dataIndex) => {
                    return <span>{data[dataIndex].address.address_line_1}</span>
                }
            }
        },
        {
            name: "", label: "Locality", options: {
                customBodyRenderLite: (dataIndex) => {
                    return <span>{data[dataIndex].address.locality}</span>
                }
            }
        },
        {
            name: "", label: "Region", options: {
                customBodyRenderLite: (dataIndex) => {
                    return <span>{data[dataIndex].address.region}</span>
                }
            }
        },

        {
            name: "", label: "Postcode", options: {
                customBodyRenderLite: (dataIndex) => {
                    return <span>{data[dataIndex].address.postcode}</span>
                }
            }
        },
        {
            name: "", label: "Action", options: {
                customBodyRenderLite: (dataIndex) => {
                    return <AppButtonComponent onClick={() => setOpen({ state: true, number: data[dataIndex].company_number })} text="Attach" btnIcon="add" style={{ color: 'white', background: context.theme.sidebar.background }} />
                }
            }
        },
    ];

    const options = {
        selectableRows: 'none'
    }

    return (
        <>
            <CompaniesHouseAttachPopup open={open.state} setOpen={setOpen} handleAttach={handleAttach} number={open.number} />
            <div className="container-fluid">
                <div className="form-row">
                    <div className="form-group col-lg-12">
                        <SearchBar
                            value={searchValue}
                            onChange={(newValue) => setSearchValue(newValue)}
                            onRequestSearch={() => handleSearch()}
                        />
                    </div>
                </div>
            </div>

            {data &&
                <FilterTable columns={columns} data={data} options={options} />
            }

        </>
    )
}