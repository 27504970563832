import React from "react";
import { AppHeaderComponent } from "@unity/components";
import SuppliersForm from "../../modules/suppliers/SuppliersForm";

export default function PageTemplateStandardCreate(props) {
  return (
    <>
      <AppHeaderComponent
        name={"suppliers"}
        subpage={"create"}
        saveBtn={props.handleSave}
        theme={props.context.theme}
        history={props.history}
        context={props.context}
      />

      <div style={{ marginTop: 146 }}>
        <SuppliersForm {...props} />
      </div>
    </>
  );
}
