import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CanDelete, CanModuleAdmin, CanUpdate } from "../services/Permissions";
import AppButton from "../common/AppButton";
import Loading from "../common/Loading";
import { RenderPageTemplate } from "../mapping";
import { SuppliersApi } from "@unity/components";

export default function SuppliersIndex({ route, context, history }) {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const columns = [
    {
      name: "",
      label: "",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].logo_path ? (
            <div
              style={{
                backgroundImage: "url(" + data[dataIndex].logo_path + ")",
                backgroundPosition: "center, center",
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
                height: 50,
                width: 50,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#d2d2d2",
                borderRadius: "100%",
                display: "inline-block",
              }}
            />
          ) : (
            <div
              style={{
                backgroundImage:
                  "url(" +
                  "https://unity.world/wp-content/uploads/brizy/9/assets/images/iW=167&iH=44&oX=1&oY=0&cW=164&cH=44/Unity-Group.png" +
                  ")",
                backgroundPosition: "center, center",
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
                height: 50,
                width: 50,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#d2d2d2",
                borderRadius: "100%",
                display: "inline-block",
              }}
            />
          );
        },
      },
    },
    { name: "reference", label: "Reference" },
    { name: "name", label: "Name" },
    { name: "last_contacted", label: "Last Contacted" },
    { name: "vat_number", label: "VAT Number" },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const edit = CanUpdate("suppliers");
          const del = CanDelete("suppliers");
          const admin = CanModuleAdmin("suppliers");

          return (
            <>
              <Link
                to={{
                  pathname: `/suppliers/view/${data[dataIndex].id}`,
                }}
              >
                <AppButton
                  text="View"
                  btnIcon="visibility"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                  }}
                />
              </Link>

              {edit && (
                <Link
                  to={{
                    pathname: `/suppliers/edit/${data[dataIndex].id}`,
                  }}
                >
                  <AppButton
                    text="edit"
                    btnIcon="edit"
                    style={{
                      background: context.theme.sidebar.background,
                      color: "white",
                      marginLeft: 10,
                    }}
                  />
                </Link>
              )}
              <AppButton
                text="call"
                btnIcon="phone"
                style={{
                  background: "green",
                  color: "white",
                  marginLeft: 10,
                }}
              />
              {admin && tabValue === 0 && (
                <>
                  <AppButton
                    onClick={() => handleSupplierReject(data[dataIndex].id)}
                    text="reject"
                    btnIcon="check"
                    style={{
                      background: "red",
                      color: "white",
                      marginLeft: 10,
                    }}
                  />
                </>
              )}
              {admin && tabValue === 1 && (
                <>
                  <AppButton
                    onClick={() => handleSupplierApprove(data[dataIndex].id)}
                    text="approve"
                    btnIcon="check"
                    style={{
                      background: "green",
                      color: "white",
                      marginLeft: 10,
                    }}
                  />
                  <AppButton
                    text="reject"
                    onClick={() => handleSupplierReject(data[dataIndex].id)}
                    btnIcon="close"
                    style={{
                      background: "red",
                      color: "white",
                      marginLeft: 10,
                    }}
                  />
                </>
              )}
              {admin && tabValue === 2 && (
                <>
                  <AppButton
                    text="approve"
                    onClick={() => handleSupplierApprove(data[dataIndex].id)}
                    btnIcon="check"
                    style={{
                      background: "green",
                      color: "white",
                      marginLeft: 10,
                    }}
                  />
                </>
              )}
            </>
          );
        },
      },
    },
  ];

  const indexMethod = {
    approved: SuppliersApi.getApprovedSuppliers,
    awaiting: SuppliersApi.getPendingSuppliers,
    denied: SuppliersApi.getUnapprovedSuppliers,
  };

  const valueString = {
    0: "approved",
    1: "awaiting",
    2: "denied",
  };

  const getSuppliersIndex = async () => {
    setLoading(true);
    const indexType = indexMethod[valueString[tabValue]];
    const data = await indexType();
    setData(data.data);
    setLoading(false);
  };

  const handleSupplierApprove = async (id) => {
    await SuppliersApi.approveSupplier(id, {});
    await getSuppliersIndex();
  };
  const handleSupplierReject = async (id) => {
    await SuppliersApi.rejectSupplier(id, {});
    await getSuppliersIndex();
  };

  useEffect(() => {
    getSuppliersIndex();
  }, [tabValue]);

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    rowsPerPage: 10,
    page: 0,
    selectableRows: "none",
  };

  const RenderPage = RenderPageTemplate(context.auth.app_uuid, "index");

  if (data) {
    return (
      <>
        <RenderPage
          columns={columns}
          loading={loading}
          tabValue={tabValue}
          setTabValue={setTabValue}
          data={data}
          options={options}
          context={context}
          history={history}
          route={route}
        />
      </>
    );
  } else {
    return <Loading />;
  }
}
