import React from 'react';
import { AppHeaderComponent, AppButtonComponent } from "@unity/components";
import { Link } from 'react-router-dom';
import SuppliersForm from '../../modules/suppliers/SuppliersForm';
export default function PageTemplateStandardView(props) {

    return (
        <>
            <AppHeaderComponent name="suppliers" context={props.context} subpage="view" subpage2={props.data.client_name} history={props.history} theme={props.context.theme}
                addNew={() => <Link to={"/suppliers/create"}>
                    <AppButtonComponent
                        text="Add New"
                        btnIcon="add"
                        stylename="primary"
                        style={
                            {
                                background: props.context.theme.top_menu.button_background,
                                color: props.context.theme.top_menu.button_font,
                            }
                        }
                    />
                </Link>}
                editItem={() => <Link to={`/suppliers/edit/${props.route.match.params.id}`}>
                    <AppButtonComponent
                        text="Edit"
                        btnIcon="edit"
                        stylename="primary"
                        style={
                            {
                                background: props.context.theme.top_menu.button_background,
                                color: props.context.theme.top_menu.button_font,
                            }
                        }
                    />
                </Link>}
            />

            <div style={{ marginTop: 146 }}>

                <SuppliersForm
                    {...props}
                />



            </div>
        </>
    )
}