import React, { useState, useEffect } from "react";
import { SuppliersApi } from "@unity/components";
import { RenderPageTemplate } from "../mapping";
import { useHistory } from "react-router";
import ApiLoader from "../common/ApiLoader";

export default function SuppliersCreate({ route, history, context }) {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState({ status: false });
  const [errors, setErrors] = useState(false);
  let newHistory = useHistory();

  const handleProductChange = (name, value, key) => {
    const newData = { ...data };
    newData.products[key][name] = value;
    setData(newData);
  };

  const handleProductDelete = (key) => {
    const newData = { ...data };
    newData.products.splice(key, 1);
    setData(newData);
  };

  const handleProductAdd = () => {
    const newData = { ...data };
    newData.products.push({});
    setData(newData);
  };

  const handleSave = async () => {
    setLoading({ status: true, data: "Saving, Please Wait...." });

    const x = await SuppliersApi.saveSuppliers(data);

    if (x.errors) {
      setLoading({ status: true, data: "Validation Errors!, Please Wait...." });
      setTimeout(() => {
        setErrors(x.errors);
        setLoading({ status: false });
      }, 3000);
    } else {
      setLoading({ status: true, data: "Save Success!, Please Wait...." });
      setTimeout(() => {
        newHistory.push("/suppliers/index");
        setLoading({ status: false });
      }, 3000);
    }
  };

  const upload = (e) => {
    const newData = { ...data };
    newData["logo_path"] = e;
    setData(newData);
  };

  const handleChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const RenderPage = RenderPageTemplate(context.auth.app_uuid, "create");

  useEffect(() => {}, []);

  return (
    <>
      <ApiLoader status={loading.status} data={loading.data} />

      <RenderPage
        data={data}
        errors={errors}
        handleChange={handleChange}
        handleProductChange={handleProductChange}
        handleProductDelete={handleProductDelete}
        handleProductAdd={handleProductAdd}
        setData={setData}
        handleSave={handleSave}
        upload={upload}
        type="create"
        context={context}
        history={history}
        route={route}
      />
    </>
  );
}
