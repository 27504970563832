import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "./services/AuthContext";
import SuppliersIndex from "./pages/index";
import SuppliersView from "./pages/view";
import SuppliersEdit from "./pages/edit";
import SuppliersCreate from "./pages/create";

export default function Root(props) {
  let context = { ...props };
  let history = useHistory();

  return (
    <AuthContext.Provider
      value={{
        ...props,
      }}
    >
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} />

                    {props.auth.access.suppliers["A"] &&
                      props.auth.access.suppliers["R"] && (
                        <Route
                          path="/suppliers/index"
                          exact
                          component={(route) => (
                            <SuppliersIndex
                              route={route}
                              history={history}
                              context={context}
                            />
                          )}
                        />
                      )}

                    {props.auth.access.suppliers["A"] &&
                      props.auth.access.suppliers["R"] && (
                        <Route
                          path="/suppliers/view/:id"
                          exact
                          component={(route) => (
                            <SuppliersView
                              route={route}
                              history={history}
                              context={context}
                            />
                          )}
                        />
                      )}
                    {props.auth.access.suppliers["A"] &&
                      props.auth.access.suppliers["U"] && (
                        <Route
                          path="/suppliers/edit/:id"
                          exact
                          component={(route) => (
                            <SuppliersEdit
                              route={route}
                              context={context}
                              history={history}
                            />
                          )}
                        />
                      )}
                    {props.auth.access.suppliers["A"] &&
                      props.auth.access.suppliers["C"] && (
                        <Route
                          path="/suppliers/create"
                          exact
                          component={(route) => (
                            <SuppliersCreate
                              route={route}
                              history={history}
                              context={context}
                            />
                          )}
                        />
                      )}
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
